import { FC } from 'react';
import IconProps from './IconProps';
import { dataAttributeProps } from '../../../utils/ComponentUtils';

const DoubleArrowRightIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg
      data-cy="document-space-icon"
      {...dataAttributeProps(props)}
      onClick={onClick}
      className={`inline ${cursor} ${className}`}
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6 9.5L10 5.5L6 1.5" stroke="#3C8CEB" strokeWidth="2" />
      <path d="M1 9.5L5 5.5L1 1.5" stroke="#3C8CEB" strokeWidth="2" />
    </svg>
  );
};

export default DoubleArrowRightIcon;
